#lightboxBackdrop.container_9tizg4 {
  background: rgba(0, 0, 0, 0.5);
  .figure_10ki57k {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    padding: 1rem;
    .footer_60scne {
      color: #333;
      padding-top: 17px;
      font-size: 19px;
      .footerCount_lkhc9u {
        color: #333;
      }
    }
  }
}

.video_thumbnail_u4nzsx, .gallery_1s3fnxy .source_video_oqobck, .gallery_1s3fnxy .video_thumbnail_3a3o1y {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.source_1v728y1 {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.jss62, .jss1 {
  z-index: 1;
}